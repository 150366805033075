import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  deleteAbsensi,
  patchPengajaran,
  postAbsensi,
  postPengajaran,
  showPengajaran,
} from "../../api";
import Input from "../../components/Input";
import Loading from "../../components/Loading";
import NoData from "../../components/NoData";
import Table, { Tbody, Td, Th, Thead, Tr } from "../../components/Table";
import Textarea from "../../components/Textarea";
import { getHari, getOrigin, saAlert, saConfirm } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import { Link } from "react-router-dom";
import { LihatProfil } from "../kelas/ListMhs";

const PengajaranAbsensi = () => {
  const { pengajaran_id } = useParams();
  const setTitle = useSetRecoilState(titleState);
  const title = useRecoilValue(titleState);
  const token = useRecoilValue(tokenState);

  const [isLoading, setIsLoading] = useState(false);

  const [items, setItems] = useState({});

  useEffect(
    () => setTitle(`${items.kelas ? items.kelas.kode : " . . ."}`),
    [items.kelas, setTitle]
  );

  const fetchData = useCallback(async () => {
    try {
      const res = await showPengajaran(pengajaran_id, token);
      setItems(res.data);
      setIsLoading(true);
    } catch (err) {
      console.log(err);
    }
  }, [pengajaran_id, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle Absen Row
  const handleAbsenSemua = () => {
    saConfirm("info", "Yakin ubah status menjadi hadir semua ?").then((res) => {
      if (res.isConfirmed) {
        const id_row = items.mhs.filter((i) => i.status !== 9).map((i) => i.id);

        postAbsensi(
          { id_row, pengajaran_id, pertemuan: items.pertemuan },
          token
        )
          .then((res) => {
            if (res.data === "success") {
              fetchData();
              saAlert("success", "Berhasil melakukan absen !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  // Handle Absen
  const handleUpdate = (mhs, pengajaran_id, pertemuan, status) => {
    postAbsensi({ mhs_id: mhs.id, pengajaran_id, pertemuan, status }, token)
      .then((res) => {
        // console.log(res.data);
        if (res.data === "success") {
          fetchData();
        }
      })
      .catch((err) => console.log(err));
  };

  // Delete Absen
  const handleCancleDelete = (a) => {
    saConfirm("warning", "Yakin hapus absensi ?").then((res) => {
      if (res.isConfirmed) {
        deleteAbsensi(a.id, token).then((res) => {
          if (res.data === "success") {
            fetchData();
            saAlert("success", "Berhasil hapus absensi !");
          }
        });
      }
    });
  };

  // Update Bap dan Bukti Pengajaran

  const [form, setForm] = useState({});

  // set Form with Items
  useEffect(() => {
    setForm({
      pembahasan: items.pembahasan ? items.pembahasan : "",
      tugas: items.tugas
        ? items.tugas
        : items.pertemuan === "UTS" || items.pertemuan === "UAS"
        ? items.pertemuan
        : "",
      hambatan: items.hambatan
        ? items.hambatan
        : items.pertemuan === "UTS" || items.pertemuan === "UAS"
        ? items.pertemuan
        : "",
    });
  }, [items]);

  const [errors, setErrors] = useState({});
  // const [errorImg, setErrorImg] = useState({});

  const [isSave, setIsSave] = useState(false);
  const [isSaveTime, setIsSaveTime] = useState(5);
  useEffect(() => {
    let status;
    let timer;

    if (isSave === true) {

      status = setTimeout(() => {
        setIsSave(false);
      }, 5000);

      timer = setInterval(() => {
        setIsSaveTime(prevState => prevState - 1);
      }, 1000);
    } else {
      clearTimeout(status);
      clearInterval(timer);

      setIsSaveTime(5);
    }

    return () => {
      clearTimeout(status);
      clearInterval(timer);
    }
  }, [isSave]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // update Bap
    const upBap = async () => {

      try {
          await patchPengajaran(items.id, { ...form, update_bap: true }, token);
          // console.log(res);
          if (!form.bukti_pengajaran) {
            setErrors({});
            fetchData();
            setIsSave(true);
            saAlert("success", "Berhasil menyimpan data pengajaran.", "Pastikan setelah melakukan simpan data untuk mengakhiri pembelajaran.");
          }
        } catch (err) {
          err.response &&
            err.response.status === 422 &&
            setErrors((prevState) => {
              return { ...prevState, ...err.response.data.errors };
            });
        }
    };

    const data = new FormData();
    data.append("bukti_pengajaran", form.bukti_pengajaran);

    // up bukti
    const upImage = async () => {
      try {
        await postPengajaran(
          data,
          token,
          `pengajaran_id=${items.id}`
        );
        // saAlert("warning", JSON.stringify(res));
        fetchData();
        setForm({ bukti_pengajaran: false });
        setIsSave(true);
        saAlert("success", "Berhasil upload Bukti Pengajaran.", "Pastikan setelah melakukan simpan data untuk mengakhiri pembelajaran.");
      } catch (err) {
        saAlert("warning", JSON.stringify(err));
        if (parseInt(err.response.status) === 401) {
          saAlert("warning", "Silahkan login terlebih dahulu.");
        } else if (parseInt(err.response.status) === 422) {
          saAlert(
            "warning",
            "Perhatian !",
            `${err.response.data.errors.bukti_pengajaran}`
          );
        } else {
          // saAlert(
          //   "warning",
          //   String(err.response.status),
          //   err.response.data.message
          // );
        }
      }
    };

    if (!items.bukti_pengajaran) {
      if (form.bukti_pengajaran) {
        upImage();
      }
    }
    upBap();
  };

  // Delete bukti peng
  const handleDeleteBuktiPeng = (id) => {
    saConfirm("warning", "Yakin hapus bukti pengajaran ?").then((res) => {
      if (res.isConfirmed) {
        patchPengajaran(id, { delete_bukti_pengajaran: true }, token)
          .then((res) => {
            if (res.data === "success") {
              fetchData();
              saAlert("success", "Berhasil hapus bukti pengajaran !");
            } else if (res.data === "fail") {
              saAlert(
                "warning",
                "Gagal hapus bukti pengajaran !",
                "Hubungi bagian Akademik & Kemahasiswaan untuk dilakukan pengecekan lebih lanjut."
              );
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const handleStartAndStop = (id, par) => {
    saConfirm(
      "warning",
      `Yakin ubah status pengajaran menjadi ${
        par.mulai ? "Mulai" : "Selesai"
      } ?`
    ).then((res) => {
      if (res.isConfirmed) {
        patchPengajaran(id, par, token)
          .then((res) => {
            if (res.data === "success") {
              fetchData();
              saAlert(
                "success",
                `Berhasil ubah status pengajaran menjadi ${
                  par.mulai ? "Mulai" : "Selesai"
                } !`
              );
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const [img, setImg] = useState("");
  const [imgName, setImgName] = useState("Kosong");

  const submitBuktiPengajaran = (e) => {
    const image = e.target.files[0];

    if (image) {
      setImg(URL.createObjectURL(image));
      setImgName(image.name);
      setForm((prevState) => {
        return { ...prevState, bukti_pengajaran: image };
      });
    }

    // function checkTypeImage (file) {
    //   const allowedExtensions = /\.(jpg|jpeg|png)$/i;
    //   return allowedExtensions.test(file.name);
    // }

    // if (image) {
    //   if (!checkTypeImage(image)) {
    //     saAlert(
    //       "warning",
    //       "Perhatian !",
    //       "Type gambar harus .jpg / .jpeg / .png."
    //     );
    //   } else {
    //     if (image.size > 10 * 1024 * 1024) {
    //       saAlert(
    //         "warning",
    //         "Perhatian !",
    //         "Ukuran gambar tidak boleh melebihi 10mb."
    //       );
    //     } else {
    //       setImg(URL.createObjectURL(image));
    //       setImgName(image.name);
    //       setForm((prevState) => {
    //         return { ...prevState, bukti_pengajaran: image };
    //       });
    //     }
    //   }
    // }
  };

  if (isLoading) {
    if (items.id) {
      return (
        <>
          <div className="row">
            <div className="col-lg-12 mt-2 px-2 pt-2">
              <div className="position-static bg-gradient-primary rounded-top-3 p-0">
                <div className="score-background rounded-top-3 shadow-nice p-4">
                  <div>
                    <div>
                      <p className="my-0 text-center text-white fw-semibold fst-italic fs-5">
                        Absensi Kelas{" "}
                        <span className="text-warning fs-5">{title}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row g-0 d-flex align-items-center">
                  <div className="col-lg-6 px-4 pt-4">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <table className="table table-sm table-borderless">
                          <tbody>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                Pertemuan ke
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                Pertemuan {items.pertemuan}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                Dosen
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                {items.dosen && items.dosen.nama}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                Jam
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                {items.dari}-{items.sampai} WITA
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                Hari & Tanggal
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                {getHari(items.hari)}, {items.tanggal}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 px-4 mb-4 mt-lg-4">
                    <div className="text-white py-lg-4">
                      {!items.mulai_id && (
                        <>
                          <h3 className="my-0 text-shadow">
                            Mulai pembelajaran saat sudah memasuki waktu
                            pembelajaran.
                          </h3>
                          <div className="mt-3">
                            <button
                              onClick={() =>
                                handleStartAndStop(items.id, { mulai: true })
                              }
                              className="px-3 btn btn-warning-2 fw-semibold mobile-w-100"
                            >
                              <i className="fa fa-play fa-sm" /> Mulai
                              Pengajaran
                            </button>
                          </div>
                        </>
                      )}
                      {items.mulai_id && !items.selesai_id && (
                        <>
                          <h3 className="text-shadow text-white">
                            <i className="fs-3 fa fa-fw fa-beat-fade fa-circle text-success-light"/> Pembelajaran sedang berlangsung...
                          </h3>
                          <h5 className="my-0 text-shadow text-gray-500">
                            Akhiri pembelajaran saat waktu pembelajaran telah
                            berakhir.
                          </h5>
                          <div>
                            <div className="mt-3">
                              <button
                                className="px-3 btn btn-danger-2 fw-semibold mobile-w-100"
                                onClick={() =>
                                  handleStartAndStop(items.id, {
                                    selesai: true,
                                  })
                                }
                              >
                                <i className="fa fa-fw fa-pause fa-beat-fade fa-sm" />{" "}
                                Akhiri Pengajaran
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {items.mulai_at && items.selesai_at && (
                        <>
                          <h3 className="mb-0 text-success-light text-shadow">
                            Pembelajaran telah sukses dilaksanakan{" "}
                            <i className="fa fa-fw fa-circle-check fs-3" />
                          </h3>

                          <table className="table table-sm table-borderless mt-2 mb-0">
                            <tbody>
                              <tr className="text-nowrap">
                                <td className="py-0 bg-none text-success-dark">
                                  <i className="fa fa-fw fa-clock" /> Dimulai
                                  pada pukul
                                </td>
                                <td className="py-0 bg-none text-success-dark">
                                  :
                                </td>
                                <td className="py-0 bg-none text-success-dark w-100">
                                  {items.mulai_at} WITA
                                </td>
                              </tr>
                              <tr className="text-nowrap">
                                <td className="py-0 bg-none text-success-dark">
                                  <i className="fa fa-fw fa-clock" /> Selesai
                                  pada pukul
                                </td>
                                <td className="py-0 bg-none text-success-dark">
                                  :
                                </td>
                                <td className="py-0 bg-none text-success-dark w-100">
                                  {items.selesai_at} WITA
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white">
                <div className="bg-primary-4 text-white rounded-bottom-3 shadow py-2">
                  <span className="d-flex justify-content-center align-items-center">
                    <i className="fa fa-fw fa-user-graduate me-1" /> Absensi
                    Mahasiswa
                  </span>
                </div>

                <div className="">
                  {items.mulai_id ?
                    <>
                      <div className="px-3 py-4">
                        <Table
                          title="Tabel absensi"
                          className="mb-0"
                          customButton={handleAbsenSemua}
                        >
                          <Thead>
                            <Tr>
                              <Th className="py-2 rounded-start-3">No</Th>
                              <Th className="py-2 border-start border-secondary">
                                Kode
                              </Th>
                              <Th className="py-2 border-start border-secondary">
                                Nama
                              </Th>
                              <Th className="py-2 border-start border-secondary">
                                <i className="fa fa-fw fa-cog mt-1"/>
                              </Th>
                              <Th className="py-2 border-start border-secondary rounded-end-3">
                                Status
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {items.mhs &&
                              items.mhs.length > 0 &&
                              items.mhs
                                .filter((i) => parseInt(i.status) !== 9)
                                .map((i, index) => {
                                  return (
                                    <Tr key={index}>
                                      <Td className="py-2 rounded-start-3">
                                        {index + 1}
                                      </Td>
                                      <Td className="py-2 border-start">{i.nim}</Td>
                                      <Td className="py-2 border-start text-start">
                                        {i.nama}
                                      </Td>
                                      <Td className="py-2 px-4 border-start">
                                        <LihatProfil mhs={i}/>
                                      </Td>
                                      <Td className="py-0 border-start rounded-end-3">
                                        {items.absensi &&
                                        items.absensi.length > 0 ? (
                                          items.absensi.filter((a) => {
                                            if (
                                              String(a.pertemuan) ===
                                              String(items.pertemuan)
                                            ) {
                                              return (
                                                parseInt(a.mhs_id) ===
                                                parseInt(i.id)
                                              );
                                            }
                                            return false;
                                          }).length > 0 ? (
                                            items.absensi.map((a, index) => {
                                              if (
                                                String(a.pertemuan) ===
                                                String(items.pertemuan)
                                              ) {
                                                if (
                                                  parseInt(a.mhs_id) ===
                                                  parseInt(i.id)
                                                ) {
                                                  return (
                                                    <div
                                                      className="d-flex justify-content-center align-items-center"
                                                      key={index}
                                                    >
                                                      {parseInt(a.status) === 1 && (
                                                        <div>
                                                          <span className="text-success-2 fw-semibold">
                                                            Hadir
                                                          </span>
                                                        </div>
                                                      )}
                                                      {parseInt(a.status) === 2 && (
                                                        <div>
                                                          <span className="text-warning fw-semibold">
                                                            Izin
                                                          </span>
                                                        </div>
                                                      )}
                                                      {parseInt(a.status) === 3 && (
                                                        <div>
                                                          <span className="text-warning fw-semibold">
                                                            Sakit
                                                          </span>
                                                        </div>
                                                      )}
                                                      {parseInt(a.status) === 4 && (
                                                        <div>
                                                          <span className="text-danger fw-semibold">
                                                            Alpa
                                                          </span>
                                                        </div>
                                                      )}
                                                      <div className="">
                                                        <button
                                                          className="mx-0 py-0 border-0 btn btn-sm"
                                                          title="Hapus absensi."
                                                          type="button"
                                                          onClick={() =>
                                                            handleCancleDelete(a)
                                                          }
                                                        >
                                                          <i className="fa fa-trash-alt fa-lg text-danger" />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              }
                                              return false;
                                            })
                                          ) : (
                                            <Absen
                                              i={i}
                                              items={items}
                                              handleUpdate={handleUpdate}
                                            />
                                          )
                                        ) : (
                                          <Absen
                                            i={i}
                                            items={items}
                                            handleUpdate={handleUpdate}
                                          />
                                        )}
                                      </Td>
                                    </Tr>
                                  );
                                })}
                          </Tbody>
                        </Table>
                      </div>

                      <div className="bg-primary text-white rounded-top-3 shadow py-2">
                        <span className="d-flex justify-content-center align-items-center">
                          <i className="fa fa-fw fa-pen me-1" /> Form Berita Acara
                          Pengajaran
                        </span>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="row g-3 p-3">
                          <div className="col-lg-4">
                            <Textarea
                              label={
                                items.pertemuan === "UTS" ||
                                items.pertemuan === "UAS"
                                  ? "Catatan"
                                  : "Pembahasan"
                              }
                              name="pembahasan"
                              value={form.pembahasan}
                              onChange={(e) =>
                                setForm((prevState) => {
                                  return {
                                    ...prevState,
                                    [e.target.name]: e.target.value,
                                  };
                                })
                              }
                              error={errors.pembahasan}
                            />
                          </div>
                          {items.pertemuan !== "UTS" &&
                            items.pertemuan !== "UAS" && (
                              <>
                                <div className="col-lg-4">
                                  <Textarea
                                    label="Tugas"
                                    name="tugas"
                                    value={form.tugas}
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                    error={errors.tugas}
                                  />
                                </div>
                                <div className="col-lg-4">
                                  <Textarea
                                    label="Hambatan"
                                    name="hambatan"
                                    value={form.hambatan}
                                    onChange={(e) =>
                                      setForm((prevState) => {
                                        return {
                                          ...prevState,
                                          [e.target.name]: e.target.value,
                                        };
                                      })
                                    }
                                    error={errors.hambatan}
                                  />
                                </div>
                              </>
                            )}

                            {items.bukti_pengajaran ? (
                              <div className="col-lg-4">
                                <div className="border bg-light rounded-3">
                                  <img
                                    className="ratio ratio-16x9 rounded-top-3"
                                    src={`${getOrigin()}/storage/bukti_pengajaran/${
                                      items.bukti_pengajaran
                                    }`}
                                    alt="Gambar Bukti Pengajaran"
                                  />
                                  <div className="p-2">
                                    <h6 className="mb-0">Gambar Bukti Pengajaran</h6>
                                    <p className="mb-2">{items.bukti_pengajaran}</p>
                                    <button
                                      type="button"
                                      className="btn btn-danger-2 btn-sm"
                                      onClick={() => handleDeleteBuktiPeng(items.id)}
                                    >
                                      <i className="fa fa-trash-alt me-2"></i>
                                      Hapus
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                              <div className='col-lg-4'>
                                <label htmlFor="cariBook" className="form-label px-2">Bukti Pengajaran <span className="text-secondary text-sm">* .jpg / .png , max 10mb</span></label>
                                  {img ? (
                                        <div className="rounded-3 border bg-light">
                                          <img src={img} className="ratio ratio-16x-9 rounded-top-3" alt="" />
                                          <div className="d-flex justify-content-between align-items-center p-2">
                                            <h6 className="my-0 text-sm">{imgName}</h6>
                                            <button
                                              onClick={() => {
                                                setImg(null)
                                                setImgName("Kosong")
                                              }} 
                                              className="bg-none border-0"
                                              >
                                              <i className="fa fa-fw fa-trash-alt text-danger"/>
                                            </button>
                                          </div>
                                        </div>
                                  ) : (
                                        <div className="rounded-3 custom-file-button p-1" 
                                          onClick={() => document.querySelector(".input-foto").click()}>
                                          <div className="box-image-2 py-5 rounded-3">
                                            <div className="text-center py-4">
                                              <i className="fa fa-fw fa-image fs-1" />
                                              <p className="my-0 text-sm mt-2">Klik disini untuk upload foto.</p>
                                            </div>
                                          </div>
                                        </div>
                                  )}
                                    <Input
                                      className="input-foto"
                                      hidden={true}
                                      name="bukti_pengajaran"
                                      type="file"
                                      accept=".jpg, .jpeg, .png"
                                      onChange={submitBuktiPengajaran}
                                      error={errors.foto}
                                    />
                              </div>
                              </>
                            )}
                        </div>
                        <div className="bg-gradient-primary rounded-bottom-3 d-flex flex-column justify-content-center align-items-center text-center px-3 py-4">
                          <p className="text-gray-500"><i className="fa fa-fw fa-sm fa-circle-exclamation text-warning"/> Sekedar informasi pastikan anda mengakhiri pembelajaran sesudah melakukan simpan data.</p>
                          <div className="btn-group w-mobile-100-desktop-50 text-center">
                            {isSave ?
                            <>
                              {items.selesai_id ?
                                <button type="submit" className="btn btn-success-2 border-0">
                                  <i className="fa fa-save me-2"></i>
                                  Simpan
                                </button>
                                :
                                <button
                                  type="button"
                                  className="px-3 btn btn-danger-2 border-0"
                                  onClick={() =>
                                    handleStartAndStop(items.id, {
                                      selesai: true,
                                    })
                                  }
                                >
                                  <i className="fa fa-fw fa-pause fa-beat-fade fa-sm" />{" "}
                                  Akhiri Pengajaran({isSaveTime})
                                </button>
                              }
                            </>
                            :
                            <button type="submit" className="btn btn-success-2 border-0">
                              <i className="fa fa-save me-2"></i>
                              Simpan
                            </button>
                            }
                            <Link
                              to="#"
                              className="btn btn-primary-3 border-0"
                              onClick={() => window.history.go(-1)}
                            >
                              <i className="fa fa-arrow-left fa-sm me-1"></i>
                              Kembali
                            </Link>
                          </div>
                        </div>
                      </form>
                    </>
                    :
                    <div className="text-secondary text-center px-3 py-5">
                      <i className="fa fa-fw fa-xl fa-circle-exclamation text-info mb-3"/>
                      <p className="mb-0">Silahkan klik tombol mulai pengajaran untuk menampilkan absen serta form pengajaran.</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <NoData />
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() => window.history.go(-1)}
            >
              Kembali
            </button>
          </div>
        </>
      );
    }
  } else {
    return <Loading />;
  }
};

const Absen = (props) => {
  const { i, items, handleUpdate } = props;
  return (
    <>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm btn-success-3 fw-semibold"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 1)}
      >
        Hadir
      </button>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm btn-warning-2 fw-semibold"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 2)}
      >
        Izin
      </button>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm btn-warning-2 fw-semibold"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 3)}
      >
        Sakit
      </button>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm btn-danger-2 fw-semibold"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 4)}
      >
        Alpa
      </button>
    </>
  );
};

export default PengajaranAbsensi;
